






import { Component, Vue, Prop } from 'vue-property-decorator'

import Header from '@/components/Header.vue'
import Page from '@/components/Page.vue'

@Component<DeleteAccountView>({
  components: {
    Header,
    Page
  },
})
export default class DeleteAccountView extends Vue {}
